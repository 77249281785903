<template>
  <div>
    <el-input disabled size="mini" placeholder="请选择链接" :value="value">
      <template slot="append">
        <div @click="dialogVisible=true" class="y-pointer">选择链接</div>
      </template>
    </el-input>
    <el-dialog custom-class="custom" append-to-body title="选择链接" :show-close="false" :visible.sync="dialogVisible" width="50rem">
      <el-tabs v-model="activeName" style="height: 20rem">
        <el-tab-pane label="系统链接" name="sys">
          <template v-for="(item,key) in sys">
            <el-button v-if="item.link != '/pages/card/plugin/principal/apply' || vuex_plugin.principal" style="margin: .5rem" @click="chooseSys(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="自定义商品专题页" name="theme">
          <template v-for="(item,key) in theme">
            <el-button @click="chooseTheme(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="文章链接">
          <template v-for="(item,key) in articleList">
            <el-button @click="chooseArticle(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="useCustom" label="自定义链接" name="custom">
          <div class="flex-def flex-zCenter flex-cCenter" style="height: 10rem">
            <el-input style="width: 30rem" placeholder="请输入" v-model="custom">
              <template slot="append">
                <el-button @click="sureCustom" style="padding:0 3rem;" size="mini">确认</el-button>
              </template>
            </el-input>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "y_choose_link",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    useCustom:{
      type:Boolean,
      default: true
    },
    modelval: {
      type:String,
      default:""
    },
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      value: this.modelval,
      custom:"",
      dialogVisible:false,
      activeName:"sys",
      sys:[
        {title:"首页",link:"/pages/index/index"},
        {title:"收入排行页",link:"/pages/award/list"},
        {title:"免单卡列表页",link:"/pages/card/coupon-list"},
        {title:"免单卡购买页",link:"/pages/card/home"},
        {title:"海报页",link:"/pages/card/mine/poster"},
        {title:"个人中心页",link:"/pages/card/mine/index"},
        {title:"商家列表",link:"/pages/card/merchant-list"},
        {title:"批发商购买激活卡",link:"/pages/card/wholesaler/buy-card"},
        {title:"文章列表页",link:"/pages/card/article/list"},
        {title:"商家入驻页",link:"/pages/card/mine/settled"},

        {title:"商城购物车页",link:"/pages/shop/cashier/cart"},
        {title:"商城海报页",link:"/pages/shop/user/invite"},
        {title:"商城商品列表页",link:"/pages/shop/goods/search"},

        {title:"主理人套餐购买页",link:"/pages/card/plugin/principal/apply"},
      ],
      theme:[],
      articleList:[],
    }
  },
  mounted() {
    this.loadTheme();
    this.loadArticle();
  },
  methods:{
    chooseTheme(key){
      let item = this.theme[key];
      this.value = `/pages/shop/goods/theme?tid=${item.id}`;
      this.dialogVisible = false;
    },
    chooseArticle(key){
      let item = this.articleList[key];
      this.value = `/pages/card/article/detail?id=${item.id}`;
      this.dialogVisible = false;
    },
    loadArticle(){
      console.log("iii")
      this.$u.api.card.article.page({page:-1}).then(res=>{
        this.articleList = res.list;
      })
    },
    loadTheme(){
      this.$u.api.shop.goods.themeSearch({page:-1}).then(res=>{
        this.theme = res.list;
      })
    },
    sureCustom(){
      this.dialogVisible=false;
      this.value = this.custom;
    },
    chooseSys(key){
      let item = this.sys[key];
      this.value = item.link;
      this.dialogVisible = false;
    },
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>
<style scoped>

</style>